import React from 'react';

const DeleteUserModal = ({ user, onDelete, setIsDeleteUserModalOpen }) => {
  
  const handleDelete = () => {
    onDelete(user); // Assuming user has an `id` property to identify the user
  };
  console.log(onDelete)
  return (
    
    <div>
      {/* Modal */}
      <div className="fixed inset-0 top-10 bottom-10 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 ">
        <div className="bg-white rounded-lg w-full max-w-md p-6 relative">
          {/* Close Button */}
          <button
            className="absolute text-2xl top-8 right-2 text-red-600 hover:text-gray-900"
            onClick={() => setIsDeleteUserModalOpen(false)}
          >
            X
          </button>

          {/* Modal Title */}
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            Confirm Deletion
          </h2>

          {/* Confirmation Message */}
          <p className="mb-4 text-gray-700">
            Are you sure you want to delete the user <strong>{user.username}</strong>? This action cannot be undone.
          </p>

          {/* Action Buttons */}
          <div className="flex justify-end">
            <button
              onClick={() => setIsDeleteUserModalOpen(false)}
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-400 mr-2"
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserModal;
