import React, { useState, useEffect } from 'react';
import { Link ,useNavigate} from 'react-router-dom';

const Navbar = ({ children ,handleLogout }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onLogout = ()=>{
    handleLogout()
    navigate('/login')
  }

  return (
    <div className="flex flex-col h-full bg-slate-600">
      {/* Header */}
      <div className="flex justify-between items-center bg-gray-900 text-white p-4">
        {/* Burger Icon */}
        <button onClick={() => setIsOpen(!isOpen)} className="md:hidden">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
        
        {/* Brand Name */}
        <div className="text-xl font-bold">Software Corp</div>
        
        {/* User Info */}
        <div className="flex items-center">
          <span className="mr-2">Username</span>
          <button onClick={() => onLogout()} className="bg-red-600 text-white p-1 rounded">Logout</button>
        </div>
      </div>

      <div className="flex flex-1 relative h-full">
        {/* Sidebar */}
        <div
          className={`bg-gray-800  h-full text-white min-h-screen transition-all duration-300 ${
            isOpen
              ? 'absolute w-64 top-0 left-0 z-50 md:relative' // Absolute only on small screens, relative on larger screens
              : 'hidden md:block w-64'
          }`}
        >
          <div className="p-4">
            <h1 className="text-2xl font-bold">Dashboard</h1>
            <ul>
              <li className="mt-4">
                <Link to="/users">Liste des Utilisateurs</Link>
              </li>
              <li className="mt-4">
                <Link to="/planActivationHistories">Historique des activations</Link>
              </li>
              <li className="mt-4">
                <Link to="/dataPlans">Forfait</Link>
              </li>
              <li className="mt-4">
                <Link to="/vouchers">Gestion Des Tickets</Link>
              </li>
              <li className="mt-4">
                <Link to="/users">Profile</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Main Content */}
        <div className={`flex-1 p-2  h-full overflow-x-auto `}>
          <div className=" font-semibold">{children }</div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
