import React from "react";

const DataPlanForm = ({
  dataPlan,
  isLoading,
  handleSubmit,
  handleInputChange,
  timeUnits,
  volumeUnits,
}) => {
  //console.log(dataPlan.isUnlimitedDataVolume)

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-gray-700">Nom Forfait:</label>
        <input
          type="text"
          name="planName"
          placeholder="Entrer nom forfait"
          value={dataPlan.planName}
          onChange={handleInputChange}
          className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Description:</label>
        <input
          type="text"
          name="description"
          placeholder="Entrer description"
          value={dataPlan.description}
          onChange={handleInputChange}
          className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Statut:</label>
        <select
          name="status"
          value={dataPlan.status} // Make sure dataPlan has this property
          onChange={handleInputChange}
          className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
        >
          <option value={1}>Active</option>
          <option value={0}>Desactive</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Prix / FCFA:</label>
        <input
          type="number"
          name="price"
          value={dataPlan.price}
          onChange={handleInputChange}
          className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Reduction / FCFA:</label>
        <input
          type="number"
          name="discount"
          value={dataPlan.discount}
          onChange={handleInputChange}
          className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4 flex">
        <div className="w-2/3 mr-2">
          <label className="block text-gray-700">Volume:</label>
          <input
            type="number"
            name="volume"
            //value={dataPlan.isUnlimitedDataVolume ? "" : dataPlan.volume} // Show empty when unlimited is checked
            value={dataPlan.volume} // Show empty when unlimited is checked
            onChange={handleInputChange}
            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="w-1/3">
          <label className="block text-gray-700">Unit:</label>
          <select
            name="volumeUnit"
            value={dataPlan.volumeUnit}
            onChange={handleInputChange}
            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
          >
            {volumeUnits.map((unit, index) => (
              <option key={index} value={unit}>
                {unit}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mb-4 flex">
        <div className="w-2/3 mr-2">
          <label className="block text-gray-700">Duree de l'utilisation</label>
          <input
            type="number"
            name="totalTime"
            //if unlimited set unlimitedTotalTime bc of validation

            value={dataPlan.totalTime}
            onChange={handleInputChange}
            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="w-1/3">
          <label className="block text-gray-700">Unit:</label>
          <select
            name="totalTimeUnit"
            value={dataPlan.totalTimeUnit}
            onChange={handleInputChange}
            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
          >
            {timeUnits.map((unit, index) => (
              <option key={index} value={unit}>
                {unit}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mb-4 mt-6 flex">
        <div className="w-2/3 mr-2">
          <label className="block text-gray-700">Periode de Validite:</label>
          <input
            type="number"
            name="validityPeriod"
            value={dataPlan.validityPeriod}
            onChange={handleInputChange}
            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="w-1/3">
          <label className="block text-gray-700">Unit:</label>
          <select
            name="validityPeriodUnit"
            value={dataPlan.validityPeriodUnit}
            onChange={handleInputChange}
            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
          >
            {timeUnits.map((unit, index) => (
              <option key={index} value={unit}>
                {unit}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Debit Up / Mpbs:</label>
        <input
          type="number"
          name="debitUp"
          value={dataPlan.debitUp}
          onChange={handleInputChange}
          className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Debit Down / Mpbs:</label>
        <input
          type="number"
          name="debitDown"
          value={dataPlan.debitDown}
          onChange={handleInputChange}
          className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
        />
      </div>

      {/* Submit Button */}
      <div className="flex justify-end">
        <button
          disabled={isLoading}
          type="submit"
          className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
        >
          Validate
        </button>
      </div>
    </form>
  );
};

export default DataPlanForm;
