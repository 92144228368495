import { useState, useContext, useEffect } from "react";

import Voucher from "./Voucher";
import AddVouchers from "./AddVouchers";

import axios from "axios";

import { postData, getData } from "./APIServices"; // Import your custom methods
import Cookies from "js-cookie";
import LoadingSpinner from "./LoadingSpinner";
import config from "../config";

const Vouchers = ({ hotspotId }) => {
  const REACT_APP_API_BASE_URL = config.APP_API_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);

  const [vouchersGroups, setVouchersGroups] = useState([]);
  const [isAddVouchersModalOpen, setIsAddVouchersModalOpen] = useState(false); // Control modal visibility
  const [dataPlanNames, setDataPlanNames] = useState({});
  const [selectedVoucherGroup, setSelectedVoucherGroup] = useState({}); // Store the selected user

  useEffect(() => {
    const fetchGroupsVouchers = async () => {
      try {
        setIsLoading(true);
        const token = Cookies.get("token");
        console.log("token:::::::::", token);
        const response = await getData(
          `${REACT_APP_API_BASE_URL}/vouchersGroups/${hotspotId}`,
          {},
          token
        );
        console.log("voucher from server ", response);
        setVouchersGroups(response.data);
      } catch (error) {
        console.error(error);
        alert(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGroupsVouchers();
  }, []);

  // Download Voucher handler
  const handleDownload = async (voucherGroup) => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/downloadVoucher/${hotspotId}/${voucherGroup.id}`,
        {
          responseType: "blob", // Important to handle file responses
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Create a download link and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url; //VGID_{voucherGroupID}_HsptID_{HospotID}
      link.setAttribute(
        "download",
        `VGID_${voucherGroup.id}_HsptID_${hotspotId}.pdf`
      ); // Give a filename
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Download error: ", error);
      alert(error);
    }
  };

  const handleOpenAddVoucherGroupModal = async () => {
    //const planNames = [];
    try {
      setIsLoading(true)
      const token = Cookies.get("token");
      const response = await getData(
        `${REACT_APP_API_BASE_URL}/planNames/${hotspotId}`,
        {},
        token
      );
      const planNames = response["data"];
      console.log(planNames);
      setDataPlanNames(planNames);
      setIsAddVouchersModalOpen(true);
    } catch (error) {
      alert(error);
    }finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col p-4 bg-gray-800 min-h-screen overflow-x-auto">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold text-white mb-4">Tickets</h1>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          onClick={handleOpenAddVoucherGroupModal}
        >
          Add
        </button>
      </div>

      {/*Main content*/}
      {vouchersGroups.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-900 text-white ">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-700">
                  Id Groupe Tickets
                </th>
                <th className="py-2 px-4 border-b border-gray-700">
                  Description
                </th>
                <th className="py-2 px-4 border-b border-gray-700">
                  Date de Creation
                </th>
                <th className="py-2 px-4 border-b border-gray-700">
                  Type de Forfait
                </th>
                <th className="py-2 px-4 border-b border-gray-700">
                  Nombre de Ticket Valide
                </th>
              </tr>
            </thead>
            <tbody>
              {vouchersGroups.map((voucherGroup) => (
                <Voucher
                  key={voucherGroup.id}
                  voucher={voucherGroup}
                  handleDownload={handleDownload}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center p-8">
          <p className="text-gray-400 text-center">
            Il n'y a actuellement aucun de Group de Vouvher à afficher.
          </p>
        </div>
      )}

      {isAddVouchersModalOpen && (
        <AddVouchers
          //onDelete={handleDeletion}
          hotspotId={hotspotId}
          vouchersGroups={vouchersGroups}
          setVouchersGroups={setVouchersGroups}
          dataPlanNames={dataPlanNames}
          setIsAddVouchersModalOpen={setIsAddVouchersModalOpen}
        />
      )}
      {/* The loading spinner will overlay the entire screen */}
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default Vouchers;
