import { useState, useContext } from "react";
import * as Yup from 'yup';

const FilterUsers = ({ setIsAddFilterUsersModalOpen , filterSettings,setFilterSettings , fetchUsers }) => {
  // Helper function to format the date to YYYY-MM-DD (required for date input fields)
  

  // Handle input changes (text and checkbox)
  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFilterSettings((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value, // Handle checkboxes properly
    }));
  };

  const validationSchema = Yup.object().shape({
    isShowUserOnlineOnly: Yup.boolean().required(),
    isShowUserTicketOnly: Yup.boolean().required(),
    isShowLastTimeConnectOnly: Yup.boolean().required(),
    lastTimeConnectStartDate: Yup.date().required('Start date is required').nullable(),
    lastTimeConnectEndDate: Yup.date()
      .min(Yup.ref('lastTimeConnectStartDate'), 'End date cannot be earlier than start date')
      .required('End date is required')
      .nullable(),
  })

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        console.log(filterSettings);
        await validationSchema.validate(filterSettings, { abortEarly: true });
      } catch (error) {
        // Handle the error, e.g., show the first error message to the user
        alert(error.errors[0]); // Display the first error message
        return
      }

    console.log("now we are going to send data")  
    await fetchUsers()
    setIsAddFilterUsersModalOpen(false)
    
  };

  return (
    <div>
      <div className="fixed inset-0 top-10 bottom-10 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 ">
        <div className="bg-white rounded-lg w-full max-w-md p-6 relative">
          {/* Close Button */}
          <button
            className="absolute text-2xl top-8 right-2 text-red-600 hover:text-gray-900"
            onClick={() => setIsAddFilterUsersModalOpen(false)}
          >
            X
          </button>

          {/* Form Title */}
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            Filtrer les utilisateurs
          </h2>

          {/* Edit Form */}
          <form onSubmit={handleSubmit}>
            {/* Online Users Filter
            <div className="mb-4 flex items-center">
              <input
                type="checkbox"
                id="isShowUserOnlineOnly"
                name="isShowUserOnlineOnly"
                checked={filterSettings.isShowUserOnlineOnly}
                onChange={handleInputChange}
                className="mr-2 w-6 h-6"
              />
              <label htmlFor="isShowUserOnlineOnly" className="text-gray-700">
                Afficher les Utilisateurs en ligne Seulement
              </label>
            </div> */}

            <div className="mb-4 flex items-center">
              <input
                type="checkbox"
                id="isShowUserTicketOnly"
                name="isShowUserTicketOnly"
                checked={filterSettings.isShowUserTicketOnly}
                onChange={handleInputChange}
                className="mr-2 w-6 h-6"
              />
              <label htmlFor="isShowUserTicketOnly" className="text-gray-700">
                Afficher les Tickets Seulement
              </label>
            </div>

            <div className="mb-4 flex items-center">
              <input
                type="checkbox"
                id="isShowUserAccountOnly"
                name="isShowUserAccountOnly"
                checked={filterSettings.isShowUserAccountOnly}
                onChange={handleInputChange}
                className="mr-2 w-6 h-6"
              />
              <label htmlFor="isShowUserAccountOnly" className="text-gray-700">
                Afficher les Account Seulement
              </label>
            </div>

            {/* Last Time Connect Filter with Start and End Date */}
            <div className="mb-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="isShowLastTimeConnectOnly"
                  name="isShowLastTimeConnectOnly"
                  checked={filterSettings.isShowLastTimeConnectOnly}
                  onChange={handleInputChange}
                  className="mr-2 w-6 h-6"
                />
                <label
                  htmlFor="isShowLastTimeConnectOnly"
                  className="text-gray-700"
                >
                  Afficher par dernière connexion
                </label>
              </div>

              {/* Start Date (Début) */}
              {filterSettings.isShowLastTimeConnectOnly && (
                <div className="mt-2">
                  <label
                    htmlFor="lastTimeConnectStartDate"
                    className="block text-gray-700"
                  >
                    Début:
                  </label>
                  <input
                    type="date"
                    id="lastTimeConnectStartDate"
                    name="lastTimeConnectStartDate"
                    value={filterSettings.lastTimeConnectStartDate}
                    onChange={handleInputChange}
                    className="p-2 border rounded-md w-full"
                  />
                </div>
              )}

              {/* End Date (Fin) */}
              {filterSettings.isShowLastTimeConnectOnly && (
                <div className="mt-2">
                  <label
                    htmlFor="lastTimeConnectEndDate"
                    className="block text-gray-700"
                  >
                    Fin:
                  </label>
                  <input
                    type="date"
                    id="lastTimeConnectEndDate"
                    name="lastTimeConnectEndDate"
                    value={filterSettings.lastTimeConnectEndDate}
                    onChange={handleInputChange}
                    className="p-2 border rounded-md w-full"
                  />
                </div>
              )}
            </div>

            {/* Submit Button */}
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
              >
                Validate
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FilterUsers;
