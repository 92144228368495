import { FiDownload } from 'react-icons/fi'; // Import the download icon



const Voucher = ({ voucher, onDelete , handleDownload }) => {

  


  return (
    <tr className="hover:bg-gray-700 border-b border-gray-700">
      <td className="py-2 px-4">{voucher.id}</td>
      <td className="py-2 px-4">{voucher.description}</td>
      <td className="py-2 px-4">{voucher.creationDateTime}</td>
      <td className="py-2 px-4">{voucher.planName}</td>
      <td className="py-2 px-4">{voucher.nbrOfTicket}</td>
      <td className="py-2 px-4 border-b border-gray-700 text-center">
        <div className="inline-block space-y-2">
          {/* Download Button */}
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded w-full flex items-center justify-center space-x-2"
            onClick={() => handleDownload(voucher)}
          >
            <FiDownload /> {/* Icon for download */}
            <span>Download</span>
          </button>

          {/* Delete Button */}
          <button
            className="bg-red-500 text-white px-3 py-1 rounded w-full"
            onClick={() => onDelete(voucher)}
          >
            Delete
          </button>
        </div>
      </td>
    </tr>
  )
}

export default Voucher
