import React from 'react'
import {  useNavigate  } from "react-router-dom"; // Import Link from react-router-dom

// Hotspot component
const Hotspot = ({ hotspot ,onHotspotSelect  }) => {
  const navigate = useNavigate();
    const c = ()=>{
      console.log("HEllo")
    }

    const handleClick = () => {

      navigate(`/users`);
      onHotspotSelect(hotspot.id)
    };

    return (
      <div className="bg-slate-700 p-4 rounded-lg shadow-md cursor-pointer hover:bg-slate-900 transition-colors hover:cursor-hand"
      onClick={handleClick}>
        <h2 className="text-xl font-semibold mb-2">{hotspot.hotspotName}</h2>
        {/* <p className="text-gray-300 mb-1">Router: {hotspot.routerName}</p> */}
        <p className="text-gray-300">Secret Radius: {hotspot.secretRadius}</p>
      </div>
    );
  }

export default Hotspot
