import * as Yup from 'yup';
import { useState , useContext , useEffect} from "react";


const EditUserModal = ({ user, onUpdate , setIsEditUserModalOpen }) => {

  // Define validation schema
  const validationSchema = Yup.object().shape({
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    status: Yup.string().required('Status is required'),
  });    
  
  //const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    //nom: user.nom,
    //username: user.username,
    //numero: user.numero,
    status: user.status,
    //category: user.category,
    password: user.portalloginpassword,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: true });
      // Proceed with form submission or other actions if validation is successful
      onUpdate(formData);
    } catch (error) {
      // Handle the error, e.g., show the first error message to the user
      alert(error.errors[0]); // Display the first error message
    }
    
  };

  return (
    <div>
      {/* Button to open modal */}
      

      {/* Modal */}
        <div className="fixed inset-0 top-10 bottom-10 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 ">
          <div className="bg-white rounded-lg w-full max-w-md p-6 relative">
            {/* Close Button */}
            <button
              className="absolute text-2xl top-8 right-2 text-red-600 hover:text-gray-900"
              onClick={() => setIsEditUserModalOpen(false)}
            >
              X
            </button>

            {/* Form Title */}
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              Edit User
            </h2>

            {/* Edit Form */}
            <form onSubmit={handleSubmit}>
              {/* <div className="mb-4">
                <label className="block text-gray-700">Nom:</label>
                <input
                  type="text"
                  name="nom"
                  value={formData.nom}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div> */}

              {/* <div className="mb-4">
                <label className="block text-gray-700">Username:</label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div> */}

              {/* <div className="mb-4">
                <label className="block text-gray-700">Numéro:</label>
                <input
                  type="text"
                  name="numero"
                  value={formData.numero}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div> */}

              <div className="mb-4">
                <label className="block text-gray-700">Status:</label>
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                >
                  <option value={true}>Active</option>
                  <option value={false}>Desactive</option>
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">Password:</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>

              {/* Submit Button */}
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
                >
                  Validate
                </button>
              </div>
            </form>
          </div>
        </div>
      
    </div>
  );
};

export default EditUserModal;
