import React from 'react';


const User = ({ user, onEdit, onDelete }) => {


  return (
    <tr className="hover:bg-gray-700">
      <td className="py-2 px-4 border-b border-gray-700">{user.name}</td>
      <td className="py-2 px-4 border-b border-gray-700">{user.username}</td>
      <td className="py-2 px-4 border-b border-gray-700">{user.number}</td>
      <td className="py-2 px-4 border-b border-gray-700">{user.dataPlan}</td>
      <td className="py-2 px-4 border-b border-gray-700">{user.dataPlanStatus}</td>
      {/* <td className="py-2 px-4 border-b border-gray-700">
        {user.status ? 'Active' : 'Desactive'}
      </td> */}
      <td className="py-2 px-4 border-b border-gray-700">{user.category}</td>
      <td className="py-2 px-4 border-b border-gray-700">{user.lastConnection}</td>
      <td className="py-2 px-4 border-b border-gray-700">{user.timeLeft}</td>
      <td className="py-2 px-4 border-b border-gray-700">{user.totalTraffic}</td>
      <td className="py-2 px-4 border-b border-gray-700">{user.portalloginpassword}</td>


     {/* Action Buttons Column */}
      <td className="py-2 px-4 border-b border-gray-700 text-center">
        <div className="inline-block space-y-2">
          {/* Edit Button */}
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded w-full"
            onClick={() => onEdit(user)}
          >
            Edit
          </button>
          {/* Delete Button */}
          <button
            className="bg-red-500 text-white px-3 py-1 rounded w-full"
            onClick={() => onDelete(user)}
          >
            Delete
          </button>
        </div>
      </td>



    </tr>
  );
};

export default User;
