import { useState, useContext, useEffect } from "react";
import { FiFilter } from "react-icons/fi"; // Import the download icon

import { getData } from "./APIServices"; // Import your custom methods
import Cookies from "js-cookie";
import LoadingSpinner from "./LoadingSpinner";

import DataPlanActivationHistory from "./DataPlanActivationHistory";
import FilterDataPlanActivationHistory from "./FilterDataPlanActivationHistory";
import config from "../config";

const DataPlanActivationHistories = ({hotspotId}) => {

    const REACT_APP_API_BASE_URL = config.APP_API_BASE_URL;

    const [isLoading, setIsLoading] = useState(false);
    const [selectedDataPlanActivationHistory, setSelectedDataPlanActivationHistory] = useState({}); // Store the selected user
    const [dataPlanActivationHistories, setDataPlanActivationHistories] = useState([]);
    const [totalRevenu, setTotalRevenu] = useState([]);
    const [isFilterPlanActivationHistoriesModalOpen, setIsFilterPlanActivationHistoriesModalOpen] = useState(false); // Control modal visibility
  
    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const getYesterdayDate = () => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return yesterday.toISOString().split('T')[0];
    };
  
    const [filterSettings, setFilterSettings] = useState({
      activationStartDate: getYesterdayDate(),
      isShowUserAccountOnly: true ,
      isShowUserTicketOnly: true,
      activationEndDate: getTodayDate(),
    });
    
    useEffect(() => {
        fetchPlanActivationHistories();
    }, [filterSettings.isShowUserAccountOnly]);

    const fetchPlanActivationHistories = async () => {
        try {
          setIsLoading(true);
          const token = Cookies.get("token");
          const response = await getData(
            `${REACT_APP_API_BASE_URL}/planActivationHistories/${hotspotId}`,
            { filterSettings },
            token
          );
          const dataFromServer = response.data;
          const planActivationHistories = dataFromServer.planActivationHistories 
          const totalRevenu = dataFromServer.totalRevenu
          setTotalRevenu(totalRevenu)
          //debugger
          //return
          if (Array.isArray(planActivationHistories) && planActivationHistories.length > 0) {
            setDataPlanActivationHistories(planActivationHistories);
          } else {
            setDataPlanActivationHistories([]);
            return;
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
  
  
    return (
      <div className="flex flex-col p-4 bg-gray-800 min-h-screen overflow-x-auto">
        {/*sub header here  */}
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-bold text-white mb-4">
            Historique des Activation Forfait
          </h1>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={() => setIsFilterPlanActivationHistoriesModalOpen(true)}
          >
            <FiFilter /> {/* Icon for download */}
          </button>
        </div>
  
        {/* online checkbox  */}
        {/* <div className="flex items-center gap-2">
          <span className="text-white text-sm">Utilisateurs Avec Compte</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={filterSettings.isShowUserAccountOnly}
              onChange={() =>{ 
                setFilterSettings({
                  ...filterSettings,
                  isShowUserAccountOnly: !filterSettings.isShowUserAccountOnly,
                })
              }}
            />
            <div className="w-11 h-6 bg-gray-600 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          </label>
        </div> */}
  
        {/* can you do toggle for me here its for show user online or not  */}
        {dataPlanActivationHistories.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-gray-900 text-white ">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b border-gray-700">Username</th>
                  <th className="py-2 px-4 border-b border-gray-700">Category</th>
                  <th className="py-2 px-4 border-b border-gray-700">Date Activation</th>
                  <th className="py-2 px-4 border-b border-gray-700">Date Exp</th>
                  <th className="py-2 px-4 border-b border-gray-700">Prix Forfait</th>
                  <th className="py-2 px-4 border-b border-gray-700">Traffic Consommer</th>
                  <th className="py-2 px-4 border-b border-gray-700">Temps Consommer</th>
                  <th className="py-2 px-4 border-b border-gray-700">Total Temps</th>
                  <th className="py-2 px-4 border-b border-gray-700">Total Traffic</th>
                  <th className="py-2 px-4 border-b border-gray-700"></th>
                </tr>
              </thead>
              <tbody>
                {dataPlanActivationHistories.map((dataPlanActivationHistory) => (
                  <DataPlanActivationHistory
                    key={dataPlanActivationHistory.id}
                    dataPlanActivationHistory={dataPlanActivationHistory}
                  />
                ))}
              </tbody>
            </table>
           
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center p-8">
            <p className="text-gray-400 text-center">
              Il n'y a actuellement aucune Activation de Forfait à afficher.
            </p>
          </div>
        )}
  
       
  
        {isFilterPlanActivationHistoriesModalOpen && (
          <FilterDataPlanActivationHistory
            //onDelete={handleDeletion}
            setIsFilterPlanActivationHistoriesModalOpen={setIsFilterPlanActivationHistoriesModalOpen}
            filterSettings={filterSettings}
            setFilterSettings={setFilterSettings}
            fetchPlanActivationHistories={fetchPlanActivationHistories}
          />
        )}
         {/* The loading spinner will overlay the entire screen */}
         {isLoading && <LoadingSpinner />}
      </div>
    );
}

export default DataPlanActivationHistories
